import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";


function Header(props) {
   const nav = useNavigate();

   function openMaps() {
      // Después pasar a Hooks de React
   }  

   function searchMaps() {
      let mySearch = document.getElementById(styles.srch_input)
      nav(`/search/maps/${mySearch.value}`)
  }

   function select(e) {
      e.preventDefault();
      e.target.style.display = 'none';
      document.querySelectorAll('.checkring').forEach(ring => ring.style.display = 'block');
      document.getElementById(`${styles.cancel}`).style.display = 'block';
   }

   function cancel(e) {
      e.preventDefault();
      e.target.style.display = 'none';
      document.querySelectorAll('.checkring').forEach(ring => {
         ring.checked = false
         ring.style.display = 'none'
         });
      document.getElementById(`${styles.select}`).style.display = 'block';
   }

   const headerBg = props.docsOrMaps === 'Maps' ? 0.85 : 1
   const reciprocal = props.docsOrMaps === 'Maps' ? 'Docs' : 'Maps'

   return <>
   <div id={styles.container} style={{opacity: headerBg}}>
      <div className="flex row center-y" id={styles.first_row}>
         <Link to='/'><h2 id={styles.logotype}>Semio<br/>Discovery</h2></Link>
         <input id={styles.srch_input} value={props.searched} readOnly />
         <button id={styles.btn} onClick={searchMaps}>Search {reciprocal}</button> {/* CHECK LATER! DIFFERENT FUNCTION DEPENDING ON WHETHER DOCS OR MAPS */}

      </div>
      <div className="flex row center-y" id={styles.second_row}>
         <p>Results by {props.docsOrMaps}</p>
      </div>
      <button id={styles.select} onClick={e => select(e)}>Select</button>
      <button id={styles.cancel} onClick={e => cancel(e)}>Cancel</button>
   </div>
   </>
}

export default Header