import React, { useCallback, useEffect } from "react"
import MagGlass from '../../components/MagGlass'
import { useNavigate } from "react-router-dom"
import styles from './style.module.css'
import DarkMode from "../../components/DarkMode";

function Home(props) {
    const nav = useNavigate();
    useEffect(() => {
        document.title = 'Semio Discovery'
    })

    const searchDocs = useCallback(() => {
        let mySearch = document.getElementById(`${styles.input}`)
        nav(`/search/docs/${mySearch.value}`)
    }, [nav])

    const searchMaps = useCallback(() => {
        let mySearch = document.getElementById(`${styles.input}`)
        nav(`/search/maps/${mySearch.value}`)
    }, [nav])

    return <div className='whole-page flex center-x center-y column'>
        <h1 id={styles.title}>Semio Discovery</h1>
        <div id={styles.inputFrame} className="flex center-y">
            <input id={styles.input} />
            <MagGlass className='mag-glass' color={'#18191E'} />
        </div>
        <div className='row btn-row evenly'>
            <button id={styles.btn} onClick={searchDocs}>Search Docs</button>
            <button id={styles.btn} onClick={searchMaps}>Search Maps</button>
        </div>
        <DarkMode />
    </div>
}

export default Home