import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../../components/SearchHeader';
import MapCode from './MapCode'
import styles from './style.module.css';


function MapResults() {
    const [mapResults, setMapResults] = useState({})
    const { search } = useParams()
    document.title = 'Map Search Results | Semio Discovery';
    useEffect(() => mapRequest())

    function mapRequest() {
        if (mapResults.status !== 1) {
            const postUrl = 'https://ist.test.intelligentsearchtechnology.com/api/get-map'
            const postBody = {
                "from": "search",
                "q": [search],
                "with": "query",
                "user_id": "auth0|anonymous",
                "user_name": "anonymous"
            }; 
            axios.post(postUrl, postBody)
            .then(function (response) {
                setMapResults(response.data.response) //Don't forget to enter .map
                window.data = response.data.response.map
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    return <>
        <Header searched={search} docsOrMaps='Maps' />
        <div id={styles.maps_container}>
            <MapCode data={window.data} />
            {window.data && window.actions.setSelecting(true)}
            {window.data && window.actions.init(window.data)}
        </div>
    </>
}

export default MapResults