import React from 'react';
import * as d3 from 'd3'
import { useD3 } from './useD3'

function MapCode({data}) {
    //INTEGRATION F(x)s
    const ref = useD3(() => {
    var initialized = false;
    var selectedNodesSet = new Set();
    var selectionMode = false;

    window.actions = {
      init(json) {
        var initSpan$ = document.getElementById("initSpan");
        if (!window.graph) { // Prevent multiple graphs
            window.graph = json;
            doGraph();
        }
      },

      setSelecting(selecting) {
        var modeSpan$ = document.getElementById("modeSpan");
        if (selecting) {
          modeSpan$.textContent = "Selecting";
          modeSpan$.style.display = "none";
        } else {
          selectedNodesSet.clear();
          Array.from(document.getElementsByTagName("text")).forEach((e) =>
            e.removeAttribute("font-weight")
          );
          modeSpan$.textContent = "Not in selection";
        }
        selectionMode = selecting;
      },
    };

    var toggleNodeSelection = function (id) {
      if (selectionMode) {
        if (id === 'DESELECT') {
          selectedNodesSet.clear();
        }
        else if (selectedNodesSet.has(id)) {
          selectedNodesSet.delete(id);
        } else {
          selectedNodesSet.add(id);
        }
        var selectedNodesArray = Array.from(selectedNodesSet);
        "webkit" in window &&
          window.webkit.messageHandlers.mapMessageHandler.postMessage(selectedNodesArray); // iOS
        "JSBridge" in window && 
          window.JSBridge.showMessageInNative(selectedNodesArray); // Android
      };
    };

    // GRAPHIC VARS
    var X_CENTER = window.innerWidth / 2;
    var Y_CENTER = window.innerHeight / 2;
    var r = 20;
    var DARK_THEME_FLAG = 1;
    var innerRingFill = [
      "#8E47E5",
      "#FE4FA3",
      "#8BF2DE",
      "#62A1FF",
      "#00C265",
      "#FF5C00",
      "#A5DF00",
      "#FF9A00",
      "#C39CF3",
      "#5200FF",
      "#B2BEB5"
    ]

    // STYLES SWITCHES (togglers)
    const blackWhenDark = () => (DARK_THEME_FLAG ? "black" : "white");
    const whiteWhenDark = () => (DARK_THEME_FLAG ? "white" : "black");
    const toggleBlackWhite = (color) => (color === "white" ? "black" : "white");
    const toggleFontWeight = (w) => (w === "bold" ? "normal" : "bold");
    const toggleSelectDisplay = (display) =>
      display === "block" ? "none" : "block";

    //////////////////
    // CANVAS SETUP //
    //////////////////
    var svg = d3
      .select("#svgID")
      .attr("width", "100%")
      .attr("height", "100vh")
      .style("background-color", blackWhenDark())

        const g = svg.append('g');
        //Pan and zoom
        svg.call(
          d3.zoom().on("zoom", function () {
            g.attr("transform", d3.event.transform);
          })
        )
        .on("dblclick.zoom", null); // Disable double click zoom

    var doGraph = function () {
      d3.json("", function (error, graph) {
        graph = window.graph;
        
        // Get max values for relative node sizes' and link widths/opacities' setup
        var linkArray = [];
        graph.links.forEach(function (link) {
          linkArray.push(link.value);
        });
        var strongestLink = Math.max(...linkArray);

        /*
        // Get the biggest node degree value (how many links does the most linked node have)
        var nodeArray = [];
        graph.nodes.forEach(function (node) {
          if (!node.degree) {
            node.degree = 0;
          }
          nodeArray.push(node.degree);
        });
        var biggestNode = Math.max(...nodeArray); */

      function getBiggestCommunities() {
        let communities = []; // create void community array
          graph.nodes.map(node => { // fill array
            /* For every node in the graph, pass it through the filter, to add its community or increase its number. */
            let filtered = communities.filter(c => c[0] === node.group)
            if (filtered.length > 0) { // Found, so increase community number then 
              filtered[0][1]++
            } else { // Not found, so it's the first one: create community index and start member count at 1
              communities.push([node.group, 1])
            }
          });
        return communities
          .sort((a, b)  => b[1] - a[1]) // sort in descending order by community size
          .slice(0,11) // trim 11 biggest
          .map(x => parseInt(x[0])); // save group names only
      }
        let topCommunitiesArray = getBiggestCommunities()

        /////////////
        // PHYSICS //
        /////////////

        // Needs constant recalibration. Feel free to adjust distances and strength
        var simulation = d3
          .forceSimulation()
          .force(
            "collide",
            d3.forceCollide((node) =>
              node.degree && r * (node.degree / 2 + 5)
            ).strength(0.3)
          ) // min distance, based on node size
          .force("center", d3.forceCenter(X_CENTER, Y_CENTER));

        simulation
          .nodes(graph.nodes)
          .force(
            "link",
            d3
              .forceLink(graph.links)
              .id((d) => d.id)
              .strength(0.3)
          ) // Strength is best between -0.05 y +1
          .on("tick", ticked); // Each time 'step'

        var spreadWhenDragged = () =>
          simulation
            .force(
              "charge",
              d3
                .forceManyBody()
                .distanceMax(Y_CENTER * 0.75)
                .strength(-800)
            )
            .force("link", d3.forceLink(graph.links).strength(0.005));

        var shrinkWhenDropped = () =>
          simulation
            .force(
              "charge",
              d3
                .forceManyBody()
                .distanceMax(Number.POSITIVE_INFINITY)
                .strength(1000)
            ) // Improved retrieval
            .force("link", d3.forceLink(graph.links).strength(0.12));

        /////////////////////////////////////////
        // SVG ELEMENT SETUP (Nodes and links) //
        /////////////////////////////////////////
        var links = g
          .append("g")
          .selectAll("line")
          .data(graph.links)
          .enter()
          .append("line")
          // LINK STYLES
          .attr(
            "stroke-width",
            (data) =>
              /* fixed min */ r * 0.15 +
              /* variable */ (0.75 * r * data.value) / strongestLink
          )
          .attr("stroke", whiteWhenDark())
          .attr(
            "opacity",
            (
              data // Line opacity also based on {data.value}
            ) =>
              data.value / strongestLink < 0.25 ? 0.25 : data.value / strongestLink
          );

        function innerNodeSize(node) {
          if (node.degree > 5) {
            return r * 2.8;
          } else if (node.degree > 1) {
            return r * node.degree/2;
          } else {
            return r * 0.4;
          }
        }

        function outerNodeSize(node) {
          if (node.degree > 5) {
            return r * 3.2;
          } else if (node.degree > 1) {
            return r * (node.degree/2 + 0.4);
          } else {
            return r * 0.8;
          }
        }

        function selectRingSize(node) {
          if (node.degree > 5) {
            return r * 3.6;
          } else if (node.degree > 1) {
            return r * (node.degree/2 + 0.8);
          } else {
            return r * 1.2;
          }
        }
        var network = g // Container
          .append("g")
          .selectAll("g")
          .data(graph.nodes)
          .enter()
          .append("g")
          .attr("id", (d) => d.id);

        var selectRing = network
          .append("circle")
          .attr("id", (d) => `${d.id}_selected`)
          .attr("r", (node) => selectRingSize(node))
          .attr("fill", blackWhenDark())
          .attr("stroke", "gold")
          .attr("stroke-width", r * 0.4)
          .style("display", "none");

        var outerNodes = network
          .append("circle")
          .attr("id", (d) => `${d.id}_outer`)
          .attr("r", (node) => outerNodeSize(node))
          .attr("fill", (node) => (node.broker ? "#30B800" : null))
          .attr("stroke", (node) => (node.broker ? "#30B800" : null))
          .attr("stroke-width", (d) => (d.degree && d.degree > 1 ? r * 0.2 : 0))
          .attr("opacity", (node) => (node.broker ? 1 : 0));

        var innerNodes = network
          .append("circle")
          .attr("id", (d) => `${d.id}_inner`)
          .attr("r", (node) => innerNodeSize(node))
          .attr("fill", (node) => {
            if (topCommunitiesArray.includes(node.group)) {
              return innerRingFill[topCommunitiesArray.indexOf(node.group)]
            } else {
              return "#555"
            }
          })
          .attr("stroke", blackWhenDark())
          .attr("stroke-width", r * 0.2);

        var labelBgs = network
          .append("text")
          .attr("id", (d) => `${d.id}_labelBg`)
          .text((d) => d.id)
          .style("stroke", blackWhenDark())
          .style("font-family", "Raleway, Helvetica, Arial, sans-serif")
          .attr("stroke-width", r)
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .style("opacity", 0.75);

        var labels = network
          .append("text")
          .attr("id", (d) => `${d.id}_label`)
          .text((d) => d.id)
          .style("font-family", "Raleway, Helvetica, Arial, sans-serif")
          .style("fill", whiteWhenDark())

        var nodeComponents = [selectRing, outerNodes, innerNodes, labelBgs, labels];

        // Finally apply nodes and links data to simulation
        simulation.nodes(graph.nodes).on("tick", ticked);
        simulation.force("link").links(graph.links);

        // FRAME-TO-FRAME RENDERING
        function ticked() {
          links
            .attr("x1", (d) => d.source.x)
            .attr("y1", (d) => d.source.y)
            .attr("x2", (d) => d.target.x)
            .attr("y2", (d) => d.target.y);

          [selectRing, outerNodes, innerNodes].forEach((item) =>
            item.attr("transform", (d) => "translate(" + d.x + "," + d.y + ")")
          );

          // Node label frame-to-frame placement
          [labelBgs, labels].forEach((item) =>
            item.attr("x", (d) => d.x + r * 1.5).attr("y", (d) => d.y + 5)
          );
        }

        ////////////////////////////
        // NODE SELECT / DESELECT //
        ////////////////////////////
        function selectNode(node) {
          //App response
          toggleNodeSelection(node.id);

          //CSS responses
          var selectedSelectRing = document.getElementById(`${node.id}_selected`);
          selectedSelectRing.style.display = toggleSelectDisplay(
            selectedSelectRing.style.display
          );

          var selectedLabelBg = document.getElementById(`${node.id}_labelBg`);
          selectedLabelBg.style.stroke = toggleBlackWhite(
            selectedLabelBg.style.stroke
          );
          selectedLabelBg.style.fontWeight = toggleFontWeight(
            selectedLabelBg.style.fontWeight
          );

          var selectedLabel = document.getElementById(`${node.id}_label`);
          selectedLabel.style.fill = toggleBlackWhite(selectedLabel.style.fill);
          selectedLabel.style.fontWeight = toggleFontWeight(
            selectedLabel.style.fontWeight
          );
          d3.event.stopPropagation();
        }

        function forceDeselect() {
          selectRing.style("display", "none");
          labelBgs.style("stroke", blackWhenDark()).style("font-weight", "normal");
          labels.style("fill", whiteWhenDark()).style("font-weight", "normal");
          toggleNodeSelection('DESELECT');
        }

        nodeComponents.forEach((item) =>
          item.on("click", function () {
            d3.event.stopPropagation();
          })
        ); // Prevent deselect when clicking node components
        nodeComponents.forEach((item) => item.on("click", selectNode)); // Select whole node when clicking any of its components

        d3.select("#svgID").on("click", () => forceDeselect());

        /////////////////////
        // NODE DRAG F(x)s //
        /////////////////////
        var drag = d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);

        nodeComponents.forEach((item) => drag(item)); // Drag whole node by grabbing any of its components

        function dragstarted(d) {
          if (!d3.event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
          spreadWhenDragged();
        }

        function dragged(d) {
          d.fx = d3.event.x;
          d.fy = d3.event.y;
          spreadWhenDragged();
        }

        function dragended(d) {
          if (!d3.event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
          shrinkWhenDropped();
        }
      });
    };
  }, []);

  return (
  <>
    <span id="initSpan"></span>
    <span id="modeSpan">Not in selection</span>
    <svg id="svgID" ref={ref} ></svg>
  </>
  )
}

  export default MapCode