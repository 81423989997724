import React from 'react'
import styles from './style.module.css'

function DarkMode() {
    let darkMode = false

    function toggleDarkMode(e) {
        if (darkMode) {
            e.target.innerHTML = '☾'
            document.body.setAttribute('mode', 'light')
            darkMode = false
        } else {
            e.target.innerHTML = '☀'
            document.body.setAttribute('mode', 'dark')
            darkMode = true
        }
    }

    document.body.setAttribute('mode', 'light')

    return (
        <button id={styles.btn} onClick={(e) => toggleDarkMode(e)}>☾</button>
    )
}

export default DarkMode