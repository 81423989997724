import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import Header from "../../components/SearchHeader"
import styles from "./style.module.css"
import wikiPlaceholder from '../../assets/wiki.jpeg'
import Expand from "../../assets/Expand"

function DocResults() {
    const [newsThreads, setNewsThreads] = useState([])
    const [wikiThreads, setWikiThreads] = useState([])
    const [checked, setChecked] = useState([])
    const [docResults, setDocResults] = useState({})
    const { search } = useParams()
    
    useEffect(() => {
        document.title = 'Doc Search Results | Semio Discovery';
    })

    const fetchResults = useCallback(async () => {
        const postUrl = 'https://ist.test.intelligentsearchtechnology.com/api/get-docs-ann'
        const postBody = {
            "from": "search",
            "q": [search],
            "with": "query",
            "user_id": "auth0|anonymous",
            "user_name": "anonymous"
        }
        const data = await axios.post(postUrl, postBody)
        const results = data.data.response
        setDocResults(results)
    }, [search])


    useEffect(() => {
        fetchResults()
            .catch(console.error)
    }, [fetchResults])

    useEffect(() => {
        docResults?.docs?.map(
            thread => 
                thread.content[0].type === 'news' ?
                    setNewsThreads(newsThreads => [...newsThreads, thread])
                    : setWikiThreads(wikiThreads => [...wikiThreads, thread])
        )
    }, [docResults])

    const checkHandler = useCallback((e) => {
        console.log(e)
        if (e.target.checked) {
            setChecked([...checked, e.target])
            console.log('New article checked: ', checked)
        } else {
            checked.filter(ring => ring.name !== e.target.name) // FIX LATER
            console.log('Article unchecked: ', checked)
        }
    }, [checked])

    const expandHandler = useCallback((e) => {
        if(e.target.hasAttribute('open')){
            e.target.style.transform = 'none'
            document.querySelectorAll(`.${e.target.getAttribute('type')}`).forEach(
                body => body.style.display = "none"
            )
            e.target.removeAttribute('open')
        } else {
            e.target.style.transform = 'rotate(-180deg) translateY(-0.4rem)'
            document.querySelectorAll(`.${e.target.getAttribute('type')}`).forEach(
                body => body.style.display = "flex"
            )
            e.target.setAttribute('open', '')
        }
    }, [])

    return <>
        <Header searched={search} docsOrMaps='Docs' />
        <div className='whole-page' id={styles.container}>
            <div className='flex center-x center-y column .under-header' id={styles.results}>  
                {newsThreads[0] && newsThreads.map(t => 
                    <div key={t.doc_id} id={styles.thread_col}>
                        <p className="p500" id={styles.keyw_margins}>{t.thread}</p>
                        <div id={styles.thread_expand} onClick={(e) => expandHandler(e)} type="news">
                            <Expand />
                        </div>
                        {t.content.map(article =>
                            <div className="news flex row" id={styles.thread_body}>
                                <a href={article.url} target='__blank' rel='noreferrer'>
                                    <div id={styles.thread_img_container} style={{
                                        backgroundPosition: 'center',
                                        backgroundImage: `url(${article.imageUrl})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        backgroundColor: 'black'
                                    }}>
                                    </div>
                                </a>
                                <input className="checkring" onClick={checkHandler} name="c1a1" type="checkbox" id={styles.checkring} />
                                <div id={styles.thread_article}>
                                    <h3 id={styles.thread_title}>NEWS: {article.title}</h3>
                                    <p id={styles.thread_text}>{article.summary}</p>
                                </div>
                            </div>
                        )}   
                    </div>
                )}
                {wikiThreads[0] && wikiThreads.map(t => 
                    <div key={t.doc_id} id={styles.thread_col}>
                        <p className="p500" id={styles.keyw_margins}>{t.thread}</p>
                        <div id={styles.thread_expand} onClick={(e) => expandHandler(e)} type="wiki">
                            <Expand />
                        </div>
                        {t.content.map(article =>
                            <div className="wiki flex row" id={styles.thread_body}>
                                <a href={article.url} target='__blank' rel='noreferrer'>
                                    <img src={article.imageUrl || wikiPlaceholder} alt="Wiki" id={article.imageUrl ? styles.thread_img : styles.thread_wiki} />
                                </a>
                                <input className="checkring" onClick={checkHandler} name="c1a1" type="checkbox" id={styles.checkring} />
                                <div id={styles.thread_article}>
                                    <h3 id={styles.thread_title}>WIKI: {article.title}</h3>
                                    <p id={styles.thread_text}>{article.summary}</p>
                                </div>
                            </div>
                        )}   
                    </div>
                )}
            </div>
        </div>
    </>
}

export default DocResults
