/* BIBLIOTECAS */
import React from 'react';
import { Route, Routes, HashRouter as Router } from 'react-router-dom';
// Views
import Home from './views/Home';
import DocResults from './views/DocResults';
import MapResults from './views/MapResults';

/* import ScrollToTop from './components/ScrollToTop' */
/* STYLES */
import './App.css';

const App = () => {
  return (
    <div>
      <Router>
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search/docs/:search" element={<DocResults />} />
          <Route path="/search/maps/:search" element={<MapResults />} />
        </Routes>
      </Router>
    </div>
  )
};

export default App;