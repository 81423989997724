import React from "react"
import styles from './style.module.css'

function MagGlass(props) {
    return (
        <svg
            className='mag-glass'
            width="18" 
            height="18" 
            viewBox="0 0 21 21" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                id={styles.fill}
                d="M20.8554 19.7522L15.5647 14.4614C15.4703 14.3671 15.3473 14.3179 15.2161 14.3179H14.7936C16.2004 12.7963 17.0617 10.7661 17.0617 8.53083C17.0617 3.81837 13.2433 0 8.53083 0C3.81837 0 0 3.81837 0 8.53083C0 13.2433 3.81837 17.0617 8.53083 17.0617C10.7661 17.0617 12.7963 16.2004 14.3179 14.7977V15.2161C14.3179 15.3473 14.3712 15.4703 14.4614 15.5647L19.7522 20.8554C19.9449 21.0482 20.2566 21.0482 20.4494 20.8554L20.8554 20.4494C21.0482 20.2566 21.0482 19.9449 20.8554 19.7522ZM8.53083 15.7492C4.54021 15.7492 1.31244 12.5215 1.31244 8.53083C1.31244 4.54021 4.54021 1.31244 8.53083 1.31244C12.5215 1.31244 15.7492 4.54021 15.7492 8.53083C15.7492 12.5215 12.5215 15.7492 8.53083 15.7492Z"
            />
        </svg>
    )
}

export default MagGlass
