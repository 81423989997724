import React from "react"

function Expand(props) {
    return (
        <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(-180)"
        >
            <path
                d="M5.292 0.764424L10 5.30673L9.063 6.20654L5.292 2.58519L1.521 6.20654L0.584 5.30673L5.292 0.764424Z"
                fill="#282B35" // TODO: Turn to variable from props
            />
        </svg>
    )
}

export default Expand
